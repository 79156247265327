import { Routes, Route, Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

import IndexLayout from 'layouts/IndexLayout';
import MainLayout from 'layouts/MainLayout';
import AuthRoutes from 'components/AuthRoutes';
import IndexPage from 'pages/index/IndexPage';
import GamenamesList from 'pages/gamenames/List';
import GamenamesView from 'pages/gamenames/View';
import GamenamesAdd from 'pages/gamenames/Add';
import GamenamesEdit from 'pages/gamenames/Edit';
import PopupList from "pages/popup/List";
import PopupView from "pages/popup/View";
import PopupAdd from "pages/popup/Add";
import PopupEdit from "pages/popup/Edit";
import ResultsList from 'pages/results/List';
import ResultsView from 'pages/results/View';
import ResultsAdd from 'pages/results/Add';
import ResultsEdit from 'pages/results/Edit';
import UsersList from 'pages/users/List';
import UsersView from 'pages/users/View';
import UsersAdd from 'pages/users/Add';
import UsersEdit from 'pages/users/Edit';
import AccountPages from 'pages/account';
import HomePage from './pages/home/HomePage';
import IndexPages from './pages/index';
import ErrorPages from './pages/errors';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/styles/layout.scss';
const App = () => {
	const auth = useAuth();
	function DefaultPage(){
		if(!auth.isLoggedIn){
			return <IndexPage />
		}
		return <Navigate to="/home" replace />;
	}
	return (
    <Routes>
      <Route exact element={<AuthRoutes />}>
        <Route element={<MainLayout />}>
          <Route path="/home" element={<HomePage />} />

          {/* gamenames pages routes */}
          <Route path="/gamenames" element={<GamenamesList />} />
          <Route
            path="/gamenames/:fieldName/:fieldValue"
            element={<GamenamesList />}
          />
          <Route
            path="/gamenames/index/:fieldName/:fieldValue"
            element={<GamenamesList />}
          />
          <Route path="/gamenames/view/:pageid" element={<GamenamesView />} />
          <Route path="/gamenames/add" element={<GamenamesAdd />} />
          <Route path="/gamenames/edit/:pageid" element={<GamenamesEdit />} />

          {/* popup pages routes */}
          <Route path="/popup" element={<PopupList />} />
          <Route path="/popup/:fieldName/:fieldValue" element={<PopupList />} />
          <Route
            path="/popup/index/:fieldName/:fieldValue"
            element={<PopupList />}
          />
          <Route path="/popup/view/:pageid" element={<PopupView />} />
          <Route path="/popup/add" element={<PopupAdd />} />
          <Route path="/popup/edit/:pageid" element={<PopupEdit />} />
		  
          {/* results pages routes */}
          <Route path="/results" element={<ResultsList />} />
          <Route
            path="/results/:fieldName/:fieldValue"
            element={<ResultsList />}
          />
          <Route
            path="/results/index/:fieldName/:fieldValue"
            element={<ResultsList />}
          />
          <Route path="/results/view/:pageid" element={<ResultsView />} />
          <Route path="/results/add" element={<ResultsAdd />} />
          <Route path="/results/edit/:pageid" element={<ResultsEdit />} />

          {/* users pages routes */}
          <Route path="/users" element={<UsersList />} />
          <Route path="/users/:fieldName/:fieldValue" element={<UsersList />} />
          <Route
            path="/users/index/:fieldName/:fieldValue"
            element={<UsersList />}
          />
          <Route path="/users/view/:pageid" element={<UsersView />} />
          <Route path="/users/add" element={<UsersAdd />} />
          <Route path="/users/edit/:pageid" element={<UsersEdit />} />
          <Route path="/account/*" element={<AccountPages />} />
        </Route>
      </Route>
      <Route exact element={<IndexLayout />}>
        <Route path="/" element={<DefaultPage />} />
        <Route path="/*" element={<IndexPages />} />
        <Route path="/error/*" element={<ErrorPages />} />
      </Route>
    </Routes>
  );
}
export default App;
