import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataSource } from 'components/DataSource';
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import { Uploader } from 'components/Uploader';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const ResultsEditPage = (props) => {
  const app = useApp();
  // form validation schema
  const validationSchema = yup.object().shape({
    game_name: yup.string().required().label("Game Name"),
    publish_time: yup.string().required().label("Publish Time"),
    date: yup.string().required().label("Date"),
    pdf_file: yup.string().nullable().label("Pdf File"),
  });
  // form default values
  const formDefaultValues = {
    game_name: "",
    publish_time: "",
    date: new Date(),
    pdf_file: "",
  };
  // Function to convert 24-hour time to 12-hour time
  const convertTo12Hour = (time) => {
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours);
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${period}`;
  };

  // Generate time options for every hour
  const timeOptions = Array.from({ length: 24 }, (v, i) => {
    let time24 = `${String(i).padStart(2, "0")}:00`;
    return {
      label: convertTo12Hour(time24),
      value: time24,
    };
  });

  //where page logics resides
  const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
  //destructure and grab what we need
  const {
    formData,
    handleSubmit,
    submitForm,
    pageReady,
    loading,
    saving,
    apiRequestError,
    inputClassName,
  } = pageController;
  //Event raised on form submit success
  function afterSubmit(response) {
    app.flashMsg(props.msgTitle, props.msgAfterSave);
    if (app.isDialogOpen()) {
      app.closeDialogs(); // if page is open as dialog, close dialog
    } else if (props.redirect) {
      app.navigate(`/results`);
    }
  }
  // loading form data from api
  if (loading) {
    return (
      <div className="p-3 text-center">
        <ProgressSpinner style={{ width: "50px", height: "50px" }} />
      </div>
    );
  }
  //display error page
  if (apiRequestError) {
    return <PageRequestError error={apiRequestError} />;
  }
  //page is ready when formdata loaded successfully
  if (pageReady) {
    return (
      <main id="ResultsEditPage" className="main-page">
        {props.showHeader && (
          <section className="page-section mb-3">
            <div className="container">
              <div className="grid justify-content-between align-items-center">
                {!props.isSubPage && (
                  <div className="col-fixed ">
                    <Button
                      onClick={() => app.navigate(-1)}
                      label=""
                      className="p-button p-button-text "
                      icon="pi pi-arrow-left"
                    />
                  </div>
                )}
                <div className="col ">
                  <Title
                    title="Edit Result"
                    titleClass="text-2xl text-primary font-bold"
                    subTitleClass="text-500"
                    separator={false}
                  />
                </div>
              </div>
            </div>
          </section>
        )}
        <section className="page-section ">
          <div className="container">
            <div className="grid ">
              <div className="md:col-9 sm:col-12 comp-grid">
                <div>
                  <Formik
                    initialValues={formData}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                      submitForm(values);
                    }}
                  >
                    {(formik) => {
                      return (
                        <Form className={`${!props.isSubPage ? "card  " : ""}`}>
                          <div className="grid">
                            <div className="col-12">
                              <div className="formgrid grid">
                                <div className="col-12 md:col-3">
                                  Game Name *
                                </div>
                                <div className="col-12 md:col-9">
                                  <DataSource apiPath="components_data/game_name_option_list">
                                    {({ response }) => (
                                      <>
                                        <Dropdown
                                          name="game_name"
                                          optionLabel="label"
                                          optionValue="value"
                                          value={formik.values.game_name}
                                          onChange={formik.handleChange}
                                          options={response}
                                          label="Game Name"
                                          placeholder="Select a value ..."
                                          className={inputClassName(
                                            formik?.errors?.game_name
                                          )}
                                        />
                                        <ErrorMessage
                                          name="game_name"
                                          component="span"
                                          className="p-error"
                                        />
                                      </>
                                    )}
                                  </DataSource>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="formgrid grid">
                                <div className="col-12 md:col-3">
                                  Publish Time *
                                </div>
                                <div className="col-12 md:col-9">
                                  <Dropdown
                                    name="publish_time"
                                    value={formik.values.publish_time}
                                    options={timeOptions}
                                    onChange={formik.handleChange}
                                    placeholder="Select Time"
                                  />
                                  <ErrorMessage
                                    name="publish_time"
                                    component="span"
                                    className="p-error"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="formgrid grid">
                                <div className="col-12 md:col-3">Date *</div>
                                <div className="col-12 md:col-9">
                                  <Calendar
                                    name="date"
                                    showButtonBar
                                    className={inputClassName(
                                      formik?.errors?.date
                                    )}
                                    dateFormat="yy-mm-dd"
                                    value={formik.values.date}
                                    onChange={formik.handleChange}
                                    showIcon
                                  />
                                  <ErrorMessage
                                    name="date"
                                    component="span"
                                    className="p-error"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="formgrid grid">
                                <div className="col-12 md:col-3">Pdf File</div>
                                <div className="col-12 md:col-9">
                                  <div
                                    className={inputClassName(
                                      formik?.errors?.pdf_file
                                    )}
                                  >
                                    <Uploader
                                      name="pdf_file"
                                      showUploadedFiles
                                      value={formik.values.pdf_file}
                                      uploadPath="fileuploader/upload/pdf_file"
                                      onChange={(paths) =>
                                        formik.setFieldValue("pdf_file", paths)
                                      }
                                      fileLimit={1}
                                      maxFileSize={3}
                                      accept=".docx,.doc,.xls,.xlsx,.xml,.csv,.pdf,.xps"
                                      multiple={false}
                                      label="Choose files or drop files here"
                                      onUploadError={(errMsg) =>
                                        app.flashMsg(
                                          "Upload error",
                                          errMsg,
                                          "error"
                                        )
                                      }
                                    />
                                  </div>
                                  <ErrorMessage
                                    name="pdf_file"
                                    component="span"
                                    className="p-error"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {props.showFooter && (
                            <div className="text-center my-3">
                              <Button
                                onClick={(e) => handleSubmit(e, formik)}
                                type="submit"
                                label="Update"
                                icon="pi pi-send"
                                loading={saving}
                              />
                            </div>
                          )}
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}
ResultsEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'results',
	apiPath: 'results/edit',
	routeName: 'resultsedit',
	submitButtonLabel: "Update",
	formValidationError: "Form is invalid",
	formValidationMsg: "Please complete the form",
	msgTitle: "Update Record",
	msgAfterSave: "Record updated successfully",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default ResultsEditPage;
