/**
 * @Category React Hook function
 * Provide single source to manage application static menus items
 * 
**/


export default function useMenus() {
    
    
    return {
      navbarTopRight: [],
      navbarTopLeft: [],
      navbarSideLeft: [
        {
          to: "/gamenames",
          label: "Game Names",
          icon: "pi pi-th-large",
          iconcolor: "",
          target: "",
        },
        {
          to: "/results",
          label: "Results",
          icon: "pi pi-th-large",
          iconcolor: "",
          target: "",
        },
        {
          to: "/users",
          label: "Users",
          icon: "pi pi-th-large",
          iconcolor: "",
          target: "",
        },
        {
          to: "/popup",
          label: "Popup",
          icon: "pi pi-th-large",
          iconcolor: "",
          target: "",
        },
      ],
      alwaysShow: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ],
      exportFormats: {
        print: {
          label: "Print",
          icon: "pi pi-print",
          type: "print",
          ext: "",
        },
        pdf: {
          label: "Pdf",
          icon: "pi pi-file-pdf",
          type: "pdf",
          ext: "pdf",
        },
        excel: {
          label: "Excel",
          icon: "pi pi-file-excel",
          type: "excel",
          ext: "xlsx",
        },
        csv: {
          label: "Csv",
          icon: "pi pi-table",
          type: "csv",
          ext: "csv",
        },
      },
    };
}